import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";

import get from "lodash/get";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import LoadingButton from "@mui/lab/LoadingButton";
import GisMapPopups from "../GisMapPopups";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import { DrawingManager } from "@react-google-maps/api";
import {
  MAP_DRAWING_MODE,
  zIndexMapping,
} from "planning/GisMap/layers/common/configuration";
import { getMarkerCoordinatesFromFeature } from "utils/map.utils";
import { setMapState } from "planning/data/planningGis.reducer";
import { postCheckFeasibility } from "Analysis/data/analysis.service";

const Feasibility = () => {
  /**
   * Parent:
   *    GisMapEventLayer
   */

  const dispatch = useDispatch();
  const featureRef = useRef();

  const [markerState, setMarkerState] = useState({
    isAdd: true,
    lat: "",
    lng: "",
  });

  useEffect(() => {
    // clean map ref
    return () => {
      try {
        featureRef.current.setMap(null);
      } catch (error) {}
    };
  }, []);

  const {
    mutate: postCheckFeasibilityMutation,
    isLoading,
    data,
  } = useMutation(postCheckFeasibility);

  /**************************** */
  //        Handlers            //
  /**************************** */
  const handleFeatureCreate = useCallback((feature) => {
    featureRef.current = feature;
    const featureCoords = getMarkerCoordinatesFromFeature(feature);
    // shape : {lat, lng}
    setMarkerState({
      isAdd: false,
      lat: featureCoords.lat,
      lng: featureCoords.lng,
    });

    // Add drag event listener to update position
    feature.addListener("dragend", () => {
      const featureCoords = getMarkerCoordinatesFromFeature(feature);
      // shape : {lat, lng}
      setMarkerState({
        isAdd: false,
        lat: featureCoords.lat,
        lng: featureCoords.lng,
      });
    });
  }, []);

  const handleLatChange = useCallback(
    (event) => {
      setMarkerState((prev) => ({ ...prev, lat: Number(event.target.value) }));
    },
    [setMarkerState]
  );

  const handleLongChange = useCallback(
    (event) => {
      setMarkerState((prev) => ({ ...prev, lng: Number(event.target.value) }));
    },
    [setMarkerState]
  );

  const handleCancel = useCallback(() => {
    dispatch(setMapState({}));
    try {
      featureRef.current.setMap(null);
    } catch (error) {}
  }, []);

  const handleSubmit = useCallback(() => {
    // shape : {lat, lng}
    postCheckFeasibilityMutation({
      location: [markerState.lng, markerState.lat],
    });
  }, [markerState]);

  const hasLatLong = markerState.lat && markerState.lng;
  const isFeasible = get(data, "is_feasible");
  const message = get(data, "message");

  return (
    <>
      <DrawingManager
        options={{
          drawingControl: false,
          markerOptions: {
            clickable: true,
            draggable: true,
            editable: true,
            geodesic: false,
            zIndex: zIndexMapping.edit,
          },
        }}
        drawingMode={markerState.isAdd ? MAP_DRAWING_MODE.point : null}
        onMarkerComplete={handleFeatureCreate}
      />
      <GisMapPopups dragId="feasibility-layer">
        <Paper>
          <Box minWidth="450px" maxWidth="550px" p={2}>
            <Typography
              color="text.secondary"
              mb={2}
              variant="h6"
              textAlign="center"
            >
              Click on map to get location.
            </Typography>
            <Stack direction="row" spacing={2} pb={2}>
              <Box flex={1}>
                <TextField
                  className="full-width"
                  type="number"
                  label="Latitude"
                  value={markerState.lat}
                  onChange={handleLatChange}
                  inputProps={{
                    step: 0.0001,
                  }}
                />
              </Box>
              <Box flex={1}>
                <TextField
                  className="full-width"
                  type="number"
                  label="Longitude"
                  value={markerState.lng}
                  onChange={handleLongChange}
                  inputProps={{
                    step: 0.0001,
                  }}
                />
              </Box>
            </Stack>
            <Stack spacing={2} direction="row">
              <LoadingButton
                sx={{ minWidth: "10em" }}
                disableElevation
                variant="contained"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={!hasLatLong}
              >
                Submit
              </LoadingButton>
              <Button
                color="error"
                sx={{ minWidth: "10em" }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
            {!isLoading && !!data ? (
              <Box
                sx={{
                  border: "1px solid",
                  borderColor: "divider",
                  padding: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.3em",
                  marginTop: 4,
                }}
              >
                <Typography
                  variant="inherit"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {isFeasible ? (
                    <DoneIcon color="success" />
                  ) : (
                    <CloseIcon color="error" />
                  )}
                  &nbsp;&nbsp;
                  {message}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Paper>
      </GisMapPopups>
    </>
  );
};

export default Feasibility;
